<template>
  <div class="edit-product">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <v-form
          ref="form"
          lazy-validation
        >
          <b-card
            no-body
            class="full-width"
          >
            <div>
              <!--begin: Wizard-->
              <div
                class="wizard wizard-4"
                id="kt_wizard_v4"
                data-wizard-state="step-first"
                data-wizard-clickable="true"
              >
                <!--begin: Wizard Nav-->
                <div
                  class="wizard-nav"
                  style="background-color: #eef0f8"
                >
                  <div class="wizard-steps">
                    <div
                      class="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Thông tin chung</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                      v-show="showCombo"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Sản phẩm Combo</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Bảo hành & vận chuyển</div>
                        </div>
                      </div>
                    </div>

                    <!--begin: History-->
                    <div
                      class="wizard-step ml-1"
                      data-wizard-type="step"
                    >
                      <div class="wizard-wrapper">
                        <div class="wizard-label">
                          <div class="wizard-title">Lịch sử</div>
                        </div>
                      </div>
                    </div>
                    <!--end: History-->
                  </div>
                </div>
                <!--end: Wizard Nav -->

                <!--begin: Wizard Body-->
                <div class="card card-custom card-shadowless rounded-top-0">
                  <div class="card-body p-0">
                    <div class="row py-8 px-8 py-lg-15 px-lg-10">
                      <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <form
                          class="form mt-0"
                          id="kt_form"
                        >
                          <!--begin: Thông tin chung-->
                          <div
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                            data-wizard-state="current"
                          >
                            <div class="col-md-12 full-width">
                              <b-row>
                                <b-col>
                                  <h4 style="color='rgb(24, 28, 50)'">
                                    Thông tin sản phẩm
                                  </h4>
                                  <b-form-group class="required-control">
                                    <label class="mt-5"> Tên sản phẩm: </label>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.form.name.$model"
                                      :state="validateState('name')"
                                      placeholder="Nhập tên"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>
                                      Yêu cầu nhập tên sản phẩm.
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                  <b-form-group>
                                    <label class="d-flex justify-content-between">
                                      <span>Mã sản phẩm:
                                        <span class="text-danger">*</span></span>
                                      <span class="d-inline-block ml-auto">
                                        <i
                                          v-if="!isEditedProductCode"
                                          class="fas fa-lock text-danger icon-size-1-rem"
                                          v-b-tooltip.html.hover.top
                                          title="Mã không được chỉnh sửa. Nếu cần chỉnh sửa, vui lòng liên hệ phòng <b>Kế toán</b>"
                                        ></i>
                                        &nbsp;
                                      </span></label>
                                    <b-form-input
                                      size="sm"
                                      type="text"
                                      v-model="$v.form.code.$model"
                                      placeholder="Nhập mã sản phẩm"
                                      :state="validateState('code')"
                                      class="mt-1"
                                      @change="genChildProduct"
                                      @keydown.space.prevent
                                      :disabled="!isEditedProductCode"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="!$v.form.code.required">Yêu cầu nhập Mã sản
                                      phẩm.</b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.maxLength">Vui lòng nhập Mã sản phẩm nhỏ hơn 50 kí tự.</b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.minLength">Vui lòng nhập Mã sản phẩm lớn hơn 3 kí
                                      tự.</b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.hasSpecialCharacters">Mã sản phẩm không được phép chứa khoảng
                                      trắng và các ký tự đặc biệt:
                                      <span class="font-weight-bolder bg-light-danger">
                                        '`~!@#$%^&amp;*(),;"&lt;&gt;?/\,_
                                      </span></b-form-invalid-feedback>
                                    <b-form-invalid-feedback v-if="!$v.form.code.checkAscent">Mã sản phẩm không được nhập có
                                      dấu</b-form-invalid-feedback>
                                  </b-form-group>

                                  <b-form-group label="Mã vạch sản phẩm:">
                                    <b-form-input
                                      size="sm"
                                      type="text"
                                      v-model="barcode"
                                      placeholder="Nhập mã vạch sản phẩm"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Sản phẩm cha:">
                                    <Autosuggest
                                      :model="selectedParent"
                                      :suggestions="filteredOptions"
                                      placeholder="sản phẩm cha"
                                      :limit="10"
                                      @select="onSelected"
                                      @change="onInputChange"
                                      suggestionName="suggestionName"
                                    />
                                  </b-form-group>
                                  <b-form-group class="required-control">
                                    <label>Danh mục sản phẩm:</label>
                                    <Autosuggest
                                      class="mt-1"
                                      :model="$v.form.selectedCate.$model"
                                      :suggestions="filteredCateOptions"
                                      :placeholder="'danh mục sản phẩm'"
                                      @select="onSelectedCate"
                                      @change="onInputCateChange"
                                      :state="validateState('selectedCate')"
                                      errorMessages="Yêu cầu chọn danh mục sản phẩm."
                                      suggestionName="suggestionName"
                                    />
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label>Danh mục nội bộ:</label>
                                    <Autosuggest
                                      class="mt-1"
                                      :model="
                                        $v.form.selectedExternalCate.$model
                                      "
                                      :suggestions="filteredExternalCateOptions"
                                      :placeholder="'danh mục nội bộ'"
                                      @select="onSelectedExternalCate"
                                      @change="onInputExternalCateChange"
                                      :state="
                                        validateState('selectedExternalCate')
                                      "
                                      errorMessages="Yêu cầu chọn danh mục nội bộ."
                                      suggestionName="suggestionName"
                                    />
                                  </b-form-group>

                                  <b-form-group
                                    label="Mã sản phẩm VAT:"
                                    :disabled="
                                      !checkPermission('PRODUCT_VAT_UPSERT')
                                    "
                                  >
                                    <b-form-input
                                      size="sm"
                                      type="text"
                                      v-model="productCodeVat"
                                      placeholder="Nhập mã sản phẩm VAT"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Tên sản phẩm VAT:"
                                    :disabled="
                                      !checkPermission('PRODUCT_VAT_UPSERT')
                                    "
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="productNameVat"
                                      placeholder="Nhập tên sản phẩm VAT"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Tên thương mại:">
                                    <b-form-input
                                      size="sm"
                                      v-model="productTradeName"
                                      placeholder="Nhập tên thương mại"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Mã hãng:">
                                    <b-form-input
                                      size="sm"
                                      v-model="productBrandCode"
                                      placeholder="Nhập mã hãng"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Alias:">
                                    <b-form-input
                                      size="sm"
                                      v-model="alias"
                                      placeholder="Nhập Alias"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Lưu ý sản phẩm:">
                                    <b-form-textarea
                                      size="sm"
                                      v-model="note"
                                      :placeholder="'Thêm lưu ý sản phẩm'"
                                      :rows="6"
                                      :max-rows="8"
                                      @change="genChildProduct"
                                    ></b-form-textarea>
                                  </b-form-group>

                                  <b-form-group label="Trạng thái:">
                                    <b-form-select
                                      v-model="selectedStatus"
                                      :options="listStatus"
                                      value-field="id"
                                      text-field="name"
                                      size="sm"
                                      @change="genChildProduct"
                                      class="select-style"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option
                                          :value="null"
                                          disabled
                                        >
                                          --Chọn trạng thái --
                                        </b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <h4
                                    style="color='rgb(24, 28, 50)'"
                                    class="mb-6"
                                  >
                                    Thông tin giao dịch
                                  </h4>
                                  <b-form-group
                                    v-if="
                                      checkPermission(
                                        'PRODUCT_ORIGINAL_PRICE_VIEW',
                                      )
                                    "
                                    label="Giá nhập:"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="importPrice"
                                      placeholder="Nhập giá nhập"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                      disabled
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Giá bán lẻ:">
                                    <b-form-input
                                      size="sm"
                                      v-model="salePrice"
                                      placeholder="Nhập giá bán"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group label="Giá bán buôn:">
                                    <b-form-input
                                      size="sm"
                                      v-model="wholesalePrice"
                                      placeholder="Nhập giá bán buôn"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label>
                                      Giá niêm yết:
                                    </label>
                                    <b-form-input
                                      size="sm"
                                      v-model="$v.form.listedPrice.$model"
                                      placeholder="Nhập giá niêm yết"
                                      :state="validateState('listedPrice')"
                                      class="mt-1 text-right"
                                      @change="genChildProduct"
                                      v-mask="mask"
                                    ></b-form-input>
                                    <b-form-invalid-feedback>Số tiền phải lớn hơn 0.</b-form-invalid-feedback>
                                  </b-form-group>

                                  <b-form-group label="Giá thu lại:">
                                    <b-form-input
                                      size="sm"
                                      v-model="returnSellingPrice"
                                      placeholder="Nhập giá thu lại"
                                      class="text-right"
                                      v-mask="mask"
                                      @change="genChildProduct"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="required-control">
                                    <label class="d-block mt-1">
                                      VAT:
                                    </label>
                                    <b-form-select
                                      class="select-style"
                                      size="sm"
                                      v-model="$v.form.productVat.$model"
                                      :state="validateState('productVat')"
                                      :options="[
                                        {
                                          id: null,
                                          name: 'Chọn thuế suất',
                                        },
                                        {
                                          id: -1,
                                          name: 'KCT',
                                        },
                                        {
                                          id: 8,
                                          name: '8%',
                                        },
                                        {
                                          id: 10,
                                          name: '10%',
                                        },
                                      ]"
                                      value-field="id"
                                      text-field="name"
                                    >
                                    </b-form-select>
                                    <b-form-invalid-feedback>
                                      Yêu cầu nhập thuế suất. Thông tin liên hệ phòng kế toán.
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                  <b-form-group class="required-control">
                                    <label class="d-block mt-1">
                                      Đơn vị tính:
                                    </label>
                                    <b-form-select
                                      class="select-style"
                                      size="sm"
                                      v-model="$v.form.unitId.$model"
                                      :options="unitOptions"
                                      value-field="id"
                                      text-field="name"
                                      :state="validateState('unitId')"
                                    >
                                    </b-form-select>
                                    <b-form-invalid-feedback>Yêu cầu chọn đơn vị tính.</b-form-invalid-feedback>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <h4
                                    style="color='rgb(24, 28, 50)'"
                                    class="mb-6"
                                  >
                                    Thuộc tính
                                  </h4>
                                  <b-form-group label="Loại sản phẩm:">
                                    <b-form-select
                                      size="sm"
                                      v-model="selectedType"
                                      :options="listType"
                                      value-field="id"
                                      text-field="name"
                                      @change="onSelectType"
                                      class="select-style"
                                      disabled
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option
                                          :value="null"
                                          disabled
                                        >Chọn loại sản
                                          phẩm</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>

                                  <b-form-group label="Xuất xứ:">
                                    <b-input-group>
                                      <b-form-select
                                        size="sm"
                                        v-model="selectedOriginal"
                                        :options="listOrginal"
                                        value-field="id"
                                        text-field="name"
                                        class="select-style"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option
                                            :value="null"
                                            disabled
                                          >Chọn xuất xứ</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group class="required-control">
                                    <label class="d-block mt-1">
                                      Thương hiệu:
                                    </label>
                                    <b-input-group>
                                      <Autosuggest
                                        :model="$v.form.searchBrandName.$model"
                                        :suggestions="filteredBrandOptions"
                                        :placeholder="'thương hiệu'"
                                        :limit="200"
                                        @select="onSelectedBrand"
                                        @change="onInputBrandChange"
                                        :state="
                                          validateState('searchBrandName')
                                        "
                                        errorMessages="Yêu cầu chọn thương hiệu."
                                      />
                                      <b-input-group-append style="height: 2.55rem">
                                        <span
                                          @click="
                                            showBrandModel = !showBrandModel
                                          "
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group label="Màu sắc">
                                    <b-input-group>
                                      <vue-multi-select
                                        v-model="selectedColor"
                                        :options="listColor"
                                        placeholder="Chọn màu sắc"
                                        option-label="name"
                                      ></vue-multi-select>
                                      <b-input-group-append>
                                        <span
                                          @click="handleOpenColorModel()"
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>

                                  <b-form-group label="Tình trạng:">
                                    <b-input-group>
                                      <vue-multi-select
                                        v-model="selectedSize"
                                        :options="listSize"
                                        placeholder="Chọn tình trạng"
                                        option-label="name"
                                        class="form-input"
                                      ></vue-multi-select>
                                      <b-input-group-append>
                                        <span
                                          @click="handleOpenStatusModel()"
                                          class="input-group-text"
                                        >
                                          <i class="fas fa-plus"></i>
                                        </span>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <div class="separator separator-dashed my-5"></div>

                              <div v-if="!selectedParent && selectedType !== 3">
                                <b-row>
                                  <b-col>
                                    <h5>Sản phẩm con</h5>
                                  </b-col>
                                </b-row>

                                <b-table
                                  v-if="listProducts.length"
                                  :items="listProducts"
                                  :fields="fields"
                                  class="table-bordered table-hover col-md-12"
                                >
                                  <template v-slot:cell(count)="row">
                                    <div class="d-flex justify-content-center">
                                      <span v-text="row.item.count"></span>
                                    </div>
                                  </template>
                                  <template
                                    v-slot:cell(status)="row"
                                    style="width: 1%"
                                  >
                                    <span
                                      v-text="bindingNameStatus(row.item.status)"
                                      class="
                                        label
                                        font-weight-bold
                                        label-lg label-inline
                                      "
                                      :class="bindingClassStatus(row.item.status)"
                                    ></span>
                                  </template>
                                  <template v-slot:cell(originalPrice)="row">
                                    <div style="text-align: end">
                                      <span v-text="
                                          convertPrice(row.item.originalPrice)
                                        "></span>
                                    </div>
                                  </template>
                                  <template v-slot:cell(sellingPrice)="row">
                                    <div style="text-align: end">
                                      <span v-text="
                                          convertPrice(row.item.sellingPrice)
                                        "></span>
                                    </div>
                                  </template>
                                  <template v-slot:cell(totalQualityInstock)="row">
                                    <div style="text-align: center">
                                      <span v-text="row.item.totalQualityInstock"></span>
                                    </div>
                                  </template>
                                </b-table>
                              </div>
                              <div v-if="
                                  !selectedClassParent && selectedType !== 3
                                ">
                                <div class="custom-control custom-switch mb-4">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customSwitch2"
                                    v-model="isGenChild"
                                    @change="genChildProduct()"
                                  />
                                  <label
                                    class="custom-control-label text-lable"
                                    for="customSwitch2"
                                    style="font-size: 14px; font-weight: 500"
                                  >Tạo sản phẩm con</label>
                                </div>
                                <div v-show="isGenChild">
                                  <b-row>
                                    <b-col md="3">
                                      <b-form-group label="Màu sắc">
                                        <vue-multi-select
                                          v-model="listColorNew"
                                          :options="listColor"
                                          placeholder="Chọn màu sắc"
                                          option-label="name"
                                          v-on:input="genChildProduct"
                                        ></vue-multi-select>
                                      </b-form-group>

                                      <b-form-group label="Tình trạng:">
                                        <vue-multi-select
                                          v-model="listSizeNew"
                                          :options="listSize"
                                          placeholder="Chọn tình trạng"
                                          option-label="name"
                                          class="form-input"
                                          v-on:input="genChildProduct"
                                        ></vue-multi-select>
                                      </b-form-group>
                                    </b-col>

                                    <b-table
                                      :items="listProductNew"
                                      :fields="fieldProductNew"
                                      hover
                                      bordered
                                      class="col-md-9 mt-4"
                                    >
                                      <template v-slot:cell(originalPrice)="row">
                                        <div style="text-align: end">
                                          <b-input
                                            disabled
                                            type="text"
                                            size="sm"
                                            class="input-style text-right"
                                            v-model="row.item.originalPrice"
                                            v-mask="mask"
                                          ></b-input>
                                        </div>
                                      </template>
                                      <template v-slot:cell(sellingPrice)="row">
                                        <div style="text-align: end">
                                          <b-input
                                            type="text"
                                            size="sm"
                                            class="input-style text-right"
                                            v-model="row.item.sellingPrice"
                                            v-mask="mask"
                                          ></b-input>
                                        </div>
                                      </template>
                                      <template v-slot:cell(actions)="row">
                                        <v-icon
                                          small
                                          class="text-danger"
                                          @click="showDeleteAlert(row.item)"
                                          v-b-tooltip
                                          title="Xóa"
                                        >mdi-delete</v-icon>
                                      </template>
                                    </b-table>
                                  </b-row>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end: Thông tin chung-->
                          <!--begin:Sản phẩm combo-->
                          <div
                            v-show="showCombo"
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                          >
                            <div
                              class="d-flex justify-content-between align-items-end pr-"
                            >
                              <p>
                                <strong>Danh sách sản phẩm con</strong>
                              </p>
                              <div class="mb-5">
                                <b-button
                                  variant="primary"
                                  size="sm"
                                  class="font-weight-bolder"
                                  v-show="checkPermission('PRODUCT_INSERT')"
                                  @click="showModalAddProductCombo"
                                >
                                  <i
                                    style="font-size: 1rem"
                                    class="flaticon2-add-1"
                                  ></i>Thêm mới
                                </b-button>
                              </div>
                            </div>
                            <b-table
                              v-if="listProductInCombo.length"
                              :items="listProductInCombo"
                              :fields="fieldTableCombo"
                              class="table-bordered table-hover col-md-12"
                            >
                              <template v-slot:cell(productCode)="row">
                                <div style="text-align: center">
                                  <span>{{ row.item.productCode }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(productName)="row">
                                <div style="text-align: center">
                                  <span>{{ row.item.productName }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(quantity)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="number"
                                    v-model="
                                      listProductInCombo[row.index].quantity
                                    "
                                    size="sm"
                                    :state="
                                      parseInt(
                                        listProductInCombo[row.index].quantity,
                                      ) ||
                                      isNaN(
                                        listProductInCombo[row.index].quantity,
                                      )
                                    "
                                  ></b-form-input>
                                  <span v-else>{{ row.item.quantity }}</span>
                                  <b-form-invalid-feedback>
                                    Số lượng phải lớn hơn 0.
                                  </b-form-invalid-feedback>
                                </div>
                              </template>
                              <template v-slot:cell(productComboPrice)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="text"
                                    v-model="
                                      listProductInCombo[row.index]
                                        .productComboPrice
                                    "
                                    v-mask="mask"
                                    size="sm"
                                  ></b-form-input>
                                  <span v-else>{{
                                    convertPrice(row.item.productComboPrice)
                                  }}</span>
                                </div>
                              </template>
                              <template v-slot:cell(productComboRate)="row">
                                <div style="text-align: center">
                                  <b-form-input
                                    v-if="listProductInCombo[row.index].isEdit"
                                    type="text"
                                    v-model="
                                      listProductInCombo[row.index]
                                        .productComboRate
                                    "
                                    size="sm"
                                  ></b-form-input>
                                  <span v-else
                                    >{{ row.item.productComboRate || 0 }}%</span
                                  >
                                </div>
                              </template>
                              <template v-slot:cell(actions)="row">
                                <div class="d-flex justify-content-center">
                                  <v-icon
                                    v-if="listProductInCombo[row.index].isEdit"
                                    class="mr-2 text-primary"
                                    small
                                    @click="updateItem(row.index, row.item)"
                                  >mdi-check</v-icon>
                                  <v-icon
                                    v-else
                                    small
                                    class="mr-2 text-warning"
                                    @click="editItemCombo(row.index, row.item)"
                                    v-b-tooltip
                                    title="Cập nhật Combo inline"
                                  >mdi-pencil</v-icon>
                                  <v-icon
                                    small
                                    class="mr-2 text-danger"
                                    @click="showDeleteProInComboAlert(row.item)"
                                    v-b-tooltip
                                    title="Xóa"
                                  >mdi-delete</v-icon>
                                </div>
                              </template>
                            </b-table>
                          </div>
                          <!--end:Sản phẩm combo-->

                          <!--begin:Bảo hành & vận chuyển-->
                          <div
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                          >
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-3">
                                  <p class="mt-4 label-header-form">
                                    <strong>Kích thước</strong>
                                  </p>
                                  <b-form-group
                                    label="Khối lượng:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="weight"
                                      placeholder="Nhập khối lượng"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều dài:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="length"
                                      placeholder="Nhập chiều dài"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều rộng:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="width"
                                      placeholder="Nhập chiều rộng sản phẩm"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Chiều cao:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="height"
                                      placeholder="Nhập chiều cao sản phẩm"
                                      type="number"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                </div>
                                <div class="col-md-3">
                                  <p class="mt-4 label-header-form">
                                    <strong>Bảo hành</strong>
                                  </p>
                                  <b-form-group
                                    label="Địa chỉ bảo hành:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      v-model="warrantyAddress"
                                      placeholder="Nhập địa chỉ bảo hành"
                                    ></b-form-input>
                                  </b-form-group>

                                  <b-form-group
                                    label="Số điện thoại:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      type="text"
                                      v-model="warrantyPhone"
                                      placeholder="Nhập số điện thoại"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    label="Số tháng bảo hành:"
                                    style="padding: 0px"
                                  >
                                    <b-form-input
                                      size="sm"
                                      type="number"
                                      v-model="warrantyMonthNo"
                                      placeholder="Nhập số tháng bảo hành"
                                      class="text-right"
                                    ></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Nội dung:">
                                    <b-form-textarea
                                      size="sm"
                                      v-model="warrantyDescription"
                                      :placeholder="'Thêm nội dung'"
                                      :rows="6"
                                      :max-rows="8"
                                    ></b-form-textarea>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--end:Bảo hành & vận chuyển-->

                          <!--start:Lịch sử-->
                          <div
                            class="pb-5 full-width"
                            data-wizard-type="step-content"
                          >
                            <b-container class="bv-example-row">
                              <b-table
                                :fields="historyFields"
                                :items="histories"
                                class="table-bordered table-hover col-md-6"
                              >
                                <template v-slot:cell(createdBy)="row">
                                  <p>{{ row.item.createdBy }}</p>
                                  <p>{{ row.item.createdAt }}</p>
                                </template>

                                <template v-slot:cell(detail)="row">
                                  <div
                                    class="orderCode"
                                    @click="viewHistoryDetail(row.item.id)"
                                    style="cursor: pointer; color: #3699ff"
                                  >
                                    <span v-text="'Chi tiết'"></span>
                                  </div>
                                </template>
                              </b-table>
                            </b-container>
                          </div>
                          <!--end:Lịch sử-->
                        </form>
                        <!--end: Wizard Form-->
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Bpdy-->
              </div>
              <!--end: Wizard-->

              <b-modal
                v-model="showOriginalModel"
                hide-footer
                title="Thêm mới xuất xứ"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Tên xuất sứ:</label>
                    <b-form-input
                      v-model="providerName"
                      size="sm"
                      placeholder="Nhập tên xuất sứ"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="createProvider()"
                    >Lưu</b-button>
                    <b-button
                      @click="showOriginalModel = !showOriginalModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showBrandModel"
                hide-footer
                title="Thêm mới thương hiệu"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Tên thương hiệu:</label>
                    <b-form-input
                      size="sm"
                      v-model="brandName"
                      placeholder="Nhập tên thương hiệu"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                      @click="createBrand()"
                    >Lưu</b-button>
                    <b-button
                      @click="showBrandModel = !showBrandModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showColorModel"
                hide-footer
                title="Thêm mới màu sắc"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Mã màu sắc:</label>
                    <b-form-input
                      v-model="colorCode"
                      size="sm"
                      placeholder="Nhập mã màu sắc"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label class="labelInput">Tên màu sắc:</label>
                    <b-form-input
                      v-model="colorName"
                      size="sm"
                      placeholder="Nhập tên màu sắc"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      @click="createProperty(colorName, colorCode)"
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                    >Lưu</b-button>
                    <b-button
                      @click="showColorModel = !showColorModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                v-model="showStatusModel"
                hide-footer
                title="Thêm mới tình trạng"
              >
                <b-row class="mb-5">
                  <b-col>
                    <label class="labelInput">Mã tình trạng:</label>
                    <b-form-input
                      v-model="statusCode"
                      size="sm"
                      placeholder="Nhập mã tình trạng"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <label class="labelInput">Tên tình trạng:</label>
                    <b-form-input
                      v-model="statusName"
                      size="sm"
                      placeholder="Nhập tên tình trạng"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button
                      @click="createProperty(statusName, statusCode)"
                      style="fontweight: 600; width: 70px"
                      variant="primary"
                      size="sm"
                    >Lưu</b-button>
                    <b-button
                      @click="showStatusModel = !showStatusModel"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                    >Hủy</b-button>
                  </b-col>
                </b-row>
              </b-modal>

              <b-modal
                ref="add-product-modal"
                hide-footer
                title="Thêm mới sản phẩm con"
              >
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Mã vạch sản phẩm:"
                          style="padding: 0px"
                        >
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="childBarCode"
                            placeholder="Nhập mã vạch sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                        <p class="mt-4 mb-4 label-header-form">
                          <strong>Thuộc tính</strong>
                        </p>
                        <b-form-group label="Màu sắc:">
                          <b-form-select
                            size="sm"
                            v-model="selectedColorChild"
                            :options="listColor"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                            class="select-style"
                          >
                            <template v-slot:first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >-- Chọn màu sắc --</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group label="Kích thước:">
                          <b-form-select
                            size="sm"
                            v-model="selectedSizeChild"
                            :options="listSize"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                            class="select-style"
                          >
                            <template v-slot:first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >-- Chọn kích thước --</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label="Trạng thái:">
                          <b-form-select
                            v-model="selectedStatus"
                            :options="listStatus"
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                            size="sm"
                            class="select-style"
                          >
                            <template v-slot:first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >--Chọn trạng thái --</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                        <p class="mt-4 mb-4 label-header-form">
                          <strong>Thông tin giao dịch</strong>
                        </p>
                        <b-form-group label="Giá nhập:">
                          <b-form-input
                            size="sm"
                            v-model="childOriginalPrice"
                            placeholder="Nhập giá bán"
                            type="number"
                            class="text-right"
                            disabled
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Giá bán:">
                          <b-form-input
                            size="sm"
                            v-model="childSalePrice"
                            placeholder="Nhập giá bán"
                            type="number"
                            class="text-right"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="createChildProduct"
                  >Lưu</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalAddProduct"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
              <b-modal
                ref="update-product-modal"
                hide-footer
                title="Cập nhật sản phẩm con"
              >
                <v-form>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label="Mã vạch sản phẩm:">
                          <b-form-input
                            size="sm"
                            v-model="childBarCode"
                            placeholder="Nhập mã vạch sản phẩm"
                          ></b-form-input>
                        </b-form-group>
                        <p class="mt-4 mb-4">
                          <strong>Thuộc tính</strong>
                        </p>
                        <b-form-group label="Mã sản phẩm:">
                          <b-form-input
                            size="sm"
                            v-model="childCode"
                            placeholder="Nhập mã sản phẩm"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Tên sản phẩm:">
                          <b-form-input
                            size="sm"
                            v-model="childName"
                            placeholder="Nhập tên sản phẩm"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <p class="mt-25 mb-4">
                          <strong>Thông tin giao dịch</strong>
                        </p>
                        <b-form-group label="Giá nhập:">
                          <b-form-input
                            size="sm"
                            v-model="childOriginalPrice"
                            placeholder="Nhập giá bán"
                            type="number"
                            class="text-right"
                            disabled
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Giá bán:">
                          <b-form-input
                            size="sm"
                            v-model="childSalePrice"
                            placeholder="Nhập giá bán"
                            type="number"
                            class="text-right"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="updateItem"
                  >Lưu</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalUpdateProduct"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
              <b-modal
                ref="add-product-combo-modal"
                hide-footer
                title="Thêm mới sản phẩm con cho combo"
              >
                <v-form
                  ref="form"
                  lazy-validation
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Chọn sản phẩm con:">
                        <Autosuggest
                          :model="searchProductForCombo"
                          :suggestions="filteredOptions"
                          placeholder=" sản phẩm con"
                          :limit="10"
                          @select="onSelectedProductForCombo"
                          @change="onInputChangeProductCombo"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Số lượng:"
                        style="padding: 0px"
                      >
                        <b-form-input
                          size="sm"
                          v-model="proComboQuantity"
                          placeholder="Nhập SL"
                          type="number"
                          class="text-right"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Nhập giá tiền combo:">
                        <b-form-input
                          size="sm"
                          v-model="productComboPrice"
                          placeholder="Giá tiền combo"
                          type="text"
                          v-mask="mask"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Nhập tỷ lệ phân bổ:"
                        style="padding: 0px"
                      >
                        <b-input-group
                          size="sm"
                          append="%"
                        >
                          <b-form-input
                            v-model="productComboRate"
                            placeholder="Tỷ lệ phân bổ"
                            class="text-right"
                            type="text"
                          ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="addProductToCombo()"
                  >Lưu</b-button>
                  <b-button
                    style="margin-left: 10px; font-weight: 600; width: 70px"
                    variant="secondary"
                    size="sm"
                    @click="hideModalAddProductCombo"
                  >Hủy</b-button>
                </v-form>
              </b-modal>
              <b-modal
                ref="history-modal"
                hide-footer
                title="Lịch sử chỉnh sửa"
                size="lg"
              >
                <b-row>
                  <b-col md="3">
                    <label class="lable-sp">Chỉnh sửa bởi: </label>
                  </b-col>
                  <b-col md="8">
                    <label>{{ history.createdBy }}</label>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col md="3">
                    <label class="lable-sp">Chỉnh sửa lúc: </label>
                  </b-col>
                  <b-col md="9">
                    <label>{{ history.createdAt }}</label>
                  </b-col>
                </b-row>
                <b-table
                  bordered
                  hover
                  :fields="historyDetailFields"
                  :items="history.diffValues"
                >
                  <template v-slot:cell(originalValue)="row">
                    <div
                      style="text-align: end"
                      v-if="row.item.isNumber"
                    >
                      <span v-text="convertPrice(row.item.originalValue)"></span>
                    </div>
                    <span
                      v-else
                      v-text="row.item.originalValue"
                    ></span>
                  </template>
                  <template v-slot:cell(newValue)="row">
                    <div
                      style="text-align: end"
                      v-if="row.item.isNumber"
                    >
                      <span v-text="convertPrice(row.item.newValue)"></span>
                    </div>
                    <span
                      v-else
                      v-text="row.item.newValue"
                    ></span>
                  </template>
                </b-table>
              </b-modal>
            </div>
          </b-card>
        </v-form>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onClickToUpdate"
          >Lưu</b-button>
          <router-link
            to="/products"
            tag="button"
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import debounce from 'debounce';
import toNumber from 'lodash/toNumber';
import { v4 } from 'uuid';
import Swal from 'sweetalert2';
import VueMultiSelect from 'vue-simple-multi-select';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import localData from '@/utils/saveDataToLocal';
import KTCodePreview from '@/view/content/CodeTab.vue';
import KTUtil from '@/assets/js/components/util';
import KTWizard from '@/assets/js/components/wizard';
import {
convertPrice,
currencyMask,
genMultilineRegexp,
makeToastFaile,
makeToastSuccess,
removeAccents,
unMaskPrice
} from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
import { PRODUCT_STATUS, PRODUCT_TYPE } from '@/utils/enum';
import { showConfirmNotify } from '@/utils/sweet-alert2';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { UNIT_OPTIONS } from '@/utils/types';

const checkCurrency = (value) => {
  const money = unMaskPrice(value);
  const moneyDec = toNumber(money);
  return moneyDec > 0;
};

const hasSpecialCharacters = (string) => {
  const regexSpecialCharacters = /[\s~`!@#$%^&*+=[\]\\';,/{}|\\":<>?()._]/g;
  const normalizedString = string.normalize().trim();
  return !regexSpecialCharacters.test(normalizedString);
};

const checkAscent = (string) => {
  const regexAscent = genMultilineRegexp(
    [
      'à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|',
      'è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|',
      'ì|í|ị|ỉ|ĩ|',
      'ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ|',
      'ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|',
      'ỳ|ý|ỵ|ỷ|ỹ|',
      'đ',
    ],
    'g',
  );
  return !regexAscent.test(string);
};

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      code: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(3),
        hasSpecialCharacters,
        checkAscent,
      },
      name: {
        required,
      },
      selectedCate: {
        required,
      },
      selectedExternalCate: {
        required,
      },
      searchBrandName: {
        required,
      },
      listedPrice: {
        required,
        checkCurrency,
      },
      unitId: {
        required,
      },
      productVat: {
        required,
      }
    },
  },
  data() {
    return {
      productType: PRODUCT_TYPE,
      productStatus: PRODUCT_STATUS,
      isEditedProductCode: false,
      form: {
        name: '',
        code: '',
        category: '',
        listedPrice: 0,
        selectedCate: '',
        selectedExternalCate: '',
        searchBrandName: '',
        unitId: null,
        productVat: null,
      },
      productCodeVat: '',
      productNameVat: '',
      productTradeName: '',
      productBrandCode: '',
      alias:'',
      productVat: null,
      returnSellingPrice: 0,
      productPropertyId: '',
      colorName: '',
      statusName: '',
      brandName: '',
      providerName: '',
      showOriginalModel: false,
      showBrandModel: false,
      showColorModel: false,
      showStatusModel: false,
      mask: currencyMask,
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center', width: '5%' },
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          sortable: false,
          thStyle: { textAlign: 'center', width: '15%' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '15%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '25%' },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'totalQualityInstock',
          label: 'Tồn kho',
          sortable: false,
          thStyle: { textAlign: 'center', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { textAlign: 'center', width: '10%' },
        },
      ],
      listProducts: [],
      fieldTableCombo: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'productComboPrice',
          label: 'Giá tiền combo',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'productComboRate',
          label: 'Tỷ lệ phân bổ ',
          sortable: false,
          thStyle: { textAlign: 'center' },
        },
        { key: 'actions', label: '' },
      ],
      barcode: '',
      importPrice: 0,
      salePrice: 0,
      warrantyAddress: '',
      warrantyPhone: '',
      warrantyMonthNo: 0,
      warrantyDescription: '',
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
      selectedParent: '',
      listColor: [],
      selectedColor: null,
      listSize: [],
      selectedSize: null,
      selectedType: null,
      listType: [
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
        { id: 3, name: 'Combo' },
        { id: 4, name: 'Sản phẩm dịch vụ' },
      ],
      selectedOriginal: null,
      listOrginal: [],
      selectedBrand: null,
      listBrand: [],
      childCode: '',
      childName: '',
      childId: 0,
      childBarCode: '',
      childOriginalPrice: 0,
      childSalePrice: 0,
      productComboPrice: '',
      productComboRate: '',
      selectedColorChild: null,
      selectedSizeChild: null,
      selectedProduct: '',
      proComboQuantity: 1,
      listProductInCombo: [],
      showCombo: false,
      selectProductComboId: 0,
      productId: '',
      selectedProductId: '',
      selectedStatus: null,
      listStatus: [
        { id: 1, name: 'Mới' },
        { id: 2, name: 'Đang bán' },
        { id: 3, name: 'Ngừng bán' },
        { id: 4, name: 'Hết hàng' },
      ],
      selectedColorOriginal: null,
      selectedSizeOriginal: null,
      options: [
        {
          data: [],
        },
      ],
      cateOptions: [],
      filteredOptions: [],
      filteredCateOptions: [],
      selectedClassParent: null,
      externalCateOptions: [],
      filteredExternalCateOptions: [],
      selectedExternalCateId: null,
      selectedCateId: null,
      selectedProductForCombo: {
        id: null,
        code: '',
        name: '',
        quantity: 0,
      },
      searchProductForCombo: '',
      wholesalePrice: 0,

      colorCode: '',
      statusCode: '',
      isSearching: false,
      historyFields: [
        {
          key: 'createdBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
          tdClass: 'textleft',
        },
        {
          key: 'actionName',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-left',
        },
        {
          key: 'detail',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-left',
        },
      ],
      histories: [],
      history: {
        createdBy: '',
        createdAt: '',
        diffValues: [],
      },
      historyDetailFields: [
        {
          key: 'keyName',
          label: 'Thông tin',
          thStyle: { textAlign: 'center', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'originalValue',
          label: 'Trước',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
        {
          key: 'newValue',
          label: 'Sau',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '40%',
          },
        },
      ],
      note: '',
      listProductNew: [],
      fieldProductNew: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '25%' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', width: '40%' },
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { textAlign: 'center', width: '15%' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { textAlign: 'center', width: '15%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { textAlign: 'center', width: '5%' },
        },
      ],
      isGenChild: false,
      listColorNew: [],
      listSizeNew: [],
      brandOptions: [],
      filteredBrandOptions: [],
      unitOptions: UNIT_OPTIONS,
    };
  },
  components: {
    KTCodePreview,
    VueMultiSelect,
    Autosuggest,
  },
  computed: {
    productIdFromUrl() {
      return this.$route.query.id || null;
    },
  },
  async created() {
    await this.getInitData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Cập nhật sản phẩm' },
    ]);

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    convertPrice,
    makeToastFaile,
    makeToastSuccess,
    showConfirmNotify,
    bindingClassStatus(status) {
      const { NEW, SELLING, STOP_SELLING, OUT_OF_STOCK } = this.productStatus;
      const statusMapping = {
        [NEW]: 'label-light-warning',
        [SELLING]: 'label-light-success',
        [STOP_SELLING]: 'label-light-danger',
        [OUT_OF_STOCK]: 'label-light-default',
      };
      return statusMapping[status] || 'label-light-default';
    },
    async getInitData() {
      try {
        this.selectedProduct = '';
        await Promise.all([
          this.getListCate(),
          this.getListExternalCate(),
          this.fetchBrand(),
          this.fetchProvider(),
          this.getColorProperty(),
          this.getSizeProperty(),
          this.getProductById(),
          this.getHistoryByProductId(),
        ]);
      } catch (error) {
        this.makeToastFaile(error);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleOpenColorModel() {
      this.showColorModel = !this.showColorModel;
      this.productPropertyId = this.listColor[0].productPropertyId;
    },
    handleOpenStatusModel() {
      this.showStatusModel = !this.showStatusModel;
      this.productPropertyId = this.listSize[0].productPropertyId;
    },
    createProperty: async function (propName, propCode) {
      const data = {
        name: propName,
        code: propCode,
        productPropertyId: this.productPropertyId,
      };
      ApiService.post('propertyValue/create', data).then(({ data }) => {
        if (data.status === 1) {
          this.getColorProperty();
          this.getSizeProperty();
          this.showColorModel = false;
          this.showStatusModel = false;
          this.makeToastSuccess(data.message);
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    getListCate: function () {
      const param = {
        page: 1,
        limit: 10,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            name: element.name,
            suggestionName: name,
          };
          this.cateOptions.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions];
      });
    },
    fetchBrand: function () {
      this.listBrand = [];
      const param = {
        page: 1,
        pageSize: 1000,
        name: '',
      };
      const paramQuery = {
        params: {
          param,
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        response.data.brands.forEach((item) => {
          const brand = {
            id: item.id,
            name: item.name,
          };
          this.listBrand.push(brand);
        });
        this.brandOptions = response.data.brands;
        this.filteredBrandOptions = [...this.brandOptions];
      });
    },
    fetchProvider: function () {
      this.listOrginal = [];
      ApiService.get('city/country').then((response) => {
        response.data.data.forEach((item) => {
          let provider = {
            id: item.id,
            name: item.name,
          };
          this.listOrginal.push(provider);
        });
      });
    },
    getColorProperty: function () {
      ApiService.get(`property/getByType/1`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listColor = data.data;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getSizeProperty: function () {
      ApiService.get(`property/getByType/2`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listSize = data.data;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    deleteChildProductCombo: function (id) {
      ApiService.put(`combos/delete/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.getListProductInCombo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    genChildProduct() {
      this.listProductNew = [];
      if (!this.isGenChild || this.selectedParent || this.selectedType === 3) {
        this.listProductNew = [];
        return;
      }
      if (this.listColorNew.length === 0 && this.listSizeNew.length) {
        for (let i = 0; i < this.listSizeNew.length; i++) {
          const size = this.listSizeNew[i];
          const product = {
            id: v4(),
            productName: this.$v.form.name.$model + ' - ' + size.name,
            productCode: this.$v.form.code.$model + '-' + size.code,
            parentProductId: this.productIdFromUrl,
            barCode: '',
            brand: this.selectedBrand,
            originalPrice: unMaskPrice(this.importPrice),
            sellingPrice: unMaskPrice(this.salePrice),
            wholesalePrice: this.wholesalePrice
              ? unMaskPrice(this.wholesalePrice)
              : 0,
            listedPrice: this.$v.form.listedPrice.$model
              ? unMaskPrice(this.$v.form.listedPrice.$model)
              : 0,
            color: null,
            size: size.id,
            isNew: true,
            note: this.note,
          };
          this.listProductNew.push(product);
        }
      } else if (this.listColorNew.length && this.listSizeNew.length === 0) {
        for (let i = 0; i < this.listColorNew.length; i++) {
          const color = this.listColorNew[i];
          const product = {
            id: v4(),
            productName: this.$v.form.name.$model + ' - ' + color.name,
            productCode: this.$v.form.code.$model + '-' + color.code,
            parentProductId: this.productIdFromUrl,
            barCode: '',
            original: this.selectedOriginal,
            brand: this.selectedBrand,
            originalPrice: unMaskPrice(this.importPrice),
            sellingPrice: unMaskPrice(this.salePrice),
            wholesalePrice: this.wholesalePrice
              ? unMaskPrice(this.wholesalePrice)
              : 0,
            listedPrice: this.$v.form.listedPrice.$model
              ? unMaskPrice(this.$v.form.listedPrice.$model)
              : 0,
            color: color.id,
            size: null,
            isNew: true,
          };
          this.listProductNew.push(product);
        }
      } else {
        for (let index = 0; index < this.listColorNew.length; index++) {
          const color = this.listColorNew[index];
          for (let i = 0; i < this.listSizeNew.length; i++) {
            const size = this.listSizeNew[i];
            const product = {
              id: v4(),
              productName:
                this.$v.form.name.$model +
                ' - ' +
                color.name +
                ' - ' +
                size.name,
              productCode:
                this.$v.form.code.$model + '-' + color.code + '-' + size.code,
              parentProductId: this.productIdFromUrl,
              barCode: '',
              originalPrice: unMaskPrice(this.importPrice),
              sellingPrice: unMaskPrice(this.salePrice),
              wholesalePrice: this.wholesalePrice
                ? unMaskPrice(this.wholesalePrice)
                : 0,
              listedPrice: this.$v.form.listedPrice.$model
                ? unMaskPrice(this.$v.form.listedPrice.$model)
                : 0,
              color: color.id,
              size: size.id,
              isNew: true,
            };
            this.listProductNew.push(product);
          }
        }
      }
    },
    bindingNameStatus(item) {
      const { NEW, SELLING, STOP_SELLING, OUT_OF_STOCK } = this.productStatus;

      const statusMapping = {
        [NEW]: 'Mới',
        [SELLING]: 'Đang bán',
        [STOP_SELLING]: 'Ngừng bán',
        [OUT_OF_STOCK]: 'Hết hàng',
      };

      return statusMapping[item] || '';
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
    showModalAddProduct() {
      this.selectedParent = null;
      this.childBarCode = '';
      this.$refs['add-product-modal'].show();
    },
    hideModalAddProduct() {
      this.$refs['add-product-modal'].hide();
    },
    showModalUpdateProduct() {
      this.selectedParent = null;
      this.$refs['update-product-modal'].show();
    },
    hideModalUpdateProduct() {
      this.$refs['update-product-modal'].hide();
    },
    editItem(item) {
      this.childId = item.id;
      this.childCode = item.productCode;
      this.childName = item.productName;
      this.childBarCode = item.barCode;
      this.childOriginalPrice = item.originalPrice;
      this.childSalePrice = item.sellingPrice;
      this.showModalUpdateProduct();
    },
    updateItem(index, item) {
      const data = {
        ...item,
        productComboPrice: unMaskPrice(item.productComboPrice),
      };

      this.listProductInCombo[index].isEdit =
        !this.listProductInCombo[index].isEdit;

      ApiService.post('combos/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.getListProductInCombo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    createChildProduct() {
      let colorName = '';
      let colorCode = '';
      let sizeName = '';
      let sizeCode = '';
      this.listColor.forEach((element) => {
        if (element.id === this.selectedColorChild) {
          colorName = element.name;
          colorCode = element.code;
        }
      });
      this.listSize.forEach((element) => {
        if (element.id === this.selectedSizeChild) {
          sizeName = element.name;
          sizeCode = element.code;
        }
      });
      const data = {
        id: this.$uuid.v4(),
        productName:
          this.$v.form.name.$model + '-' + colorName + '-' + sizeName,
        productCode:
          this.$v.form.code.$model + '-' + colorCode + '-' + sizeCode,
        parentId: this.productIdFromUrl,
        barCode: this.childBarCode,
        original: this.selectedOriginal,
        status: this.selectedStatus,
        productType: this.selectedType,
        brand: this.selectedBrand,
        originalPrice: this.childOriginalPrice,
        sellingPrice: this.childSalePrice,
        totalQualityInstock: 0,
        productCategory: this.$v.form.selectedCate.$model,
        color: this.selectedColorChild,
        size: this.selectedSizeChild,
        weight: this.weight,
        length: this.length,
        width: this.width,
        height: this.height,
        warrantyAddress: this.warrantyAddress,
        warrantyPhone: this.warrantyPhone,
        warrantyMonthNo: this.warrantyMonthNo,
        warrantyDescription: this.warrantyDescription,
        deleted: 0,
      };
      ApiService.post('product/create-product', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.getListProductChild();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
      this.hideModalAddProduct();
    },
    showModalAddProductCombo() {
      this.searchProductForCombo = '';
      this.productComboPrice = 0;
      this.selectedProduct = '';
      this.proComboQuantity = 1;
      this.productComboRate = '';
      this.$refs['add-product-combo-modal'].show();
    },
    hideModalAddProductCombo() {
      this.$refs['add-product-combo-modal'].hide();
    },
    searchProduct: async function (textSearch) {
      const param = {
        name: textSearch,
      };

      const paramQuery = {
        params: param,
      };
      ApiService.query('product/searchProductByName', paramQuery).then(
        (response) => {
          this.options[0].data = [];
          response.data.data.forEach((item) => {
            item.productCode = item.productCode ? item.productCode : '';
            let suggestionName = item.productName;
            if (item.productCode !== '') {
              suggestionName = item.productCode + ' - ' + item.productName;
            }
            const product = {
              id: item.id,
              name: item.productName,
              code: item.productCode,
              suggestionName: suggestionName,
            };

            this.options[0].data.push(product);
          });
          this.filteredOptions = [...this.options[0].data];
        },
      );
    },
    addProductToCombo() {
      const data = {
        id: this.$uuid.v4(),
        productId: this.selectedProductForCombo.id,
        quantity: this.proComboQuantity || 1,
        productComboId: this.productIdFromUrl,
        productComboPrice: unMaskPrice(this.productComboPrice),
        productComboRate: parseFloat(toNumber(this.productComboRate || 0)),
      };
      const duplicateIndex = this.listProductInCombo.findIndex(
        (element) => element.productId === data.productId,
      );
      if (duplicateIndex !== -1) {
        this.makeToastFaile('Sản phẩm trùng!');
        return;
      }
      ApiService.post('combos/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.getListProductInCombo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
      this.hideModalAddProductCombo();
    },
    onSelectType() {
      if (this.selectedType !== this.productType.PRODUCT_COMBO) {
        this.showCombo = false;
        return;
      }
      this.genChildProduct();
      this.showCombo = true;
    },
    editItemCombo(index, item) {
      this.selectProductComboId = item.id;
      this.selectedProductForCombo.id = item.productId;
      this.searchProductForCombo = item.productName;
      this.proComboQuantity = item.quantity || 1;
      this.listProductInCombo[index].isEdit =
        !this.listProductInCombo[index].isEdit;
    },
    updateItemCombo() {
      const data = {
        id: this.selectProductComboId,
        productId: this.selectedProductForCombo.id,
        productComboId: this.productIdFromUrl,
        quantity: this.proComboQuantity || 1,
      };
      ApiService.post('combos/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.getListProductInCombo();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
      this.hideModalUpdateProductCombo();
    },
    showDeleteProInComboAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm con của commo!',
        text: 'Bạn có chắc muốn xóa sản phẩm con này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteChildProductCombo(item.id);
        }
      });
    },
    sumComboPrice() {
      return this.listProductInCombo.reduce((sum, productInCombo) => {
        return sum + toNumber(unMaskPrice(productInCombo.productComboPrice));
      }, 0)
    },
    async onClickToUpdate() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const content = {
        title: `Cập nhật sản phẩm`,
        text: `Bạn có chắc cập nhật sản phẩm #${this.form.name} không ?`,
        actionName: 'Xác nhận',
        confirmButtonColor: '#3699FF',
      };
      const result = await this.showConfirmNotify(content);
      if (!result.value) return;

      const color =
        this.selectedColor.length > 0 ? this.selectedColor[0].id : null;
      const size =
        this.selectedSize.length > 0 ? this.selectedSize[0].id : null;

      if (this.selectedParent === '') {
        this.selectedClassParent = null;
      }

      const productName = this.$v.form.name.$model
        ? this.$v.form.name.$model.trim()
        : null;
      const productCode = this.$v.form.code.$model
        ? this.$v.form.code.$model.trim()
        : null;

      if (
        !this.$v.form.selectedCate.$model ||
        !this.$v.form.selectedCate.$model.trim()
      ) {
        this.selectedCateId = null;
      }

      if (
        !this.$v.form.selectedExternalCate.$model ||
        !this.$v.form.selectedExternalCate.$model.trim()
      ) {
        this.selectedExternalCateId = null;
      }

      if (
        !this.$v.form.searchBrandName.$model ||
        !this.$v.form.searchBrandName.$model.trim()
      ) {
        this.selectedBrand = null;
      }

      if (!productCode) {
        this.makeToastFaile('Vui lòng nhập mã sản phẩm!');
        return;
      }

      if (!this.selectedCateId) {
        this.makeToastFaile('Vui lòng chọn danh mục sản phẩm!');
        return;
      }

      if (!this.selectedExternalCateId) {
        this.makeToastFaile('Vui lòng chọn danh mục nội bộ!');
        return;
      }

      if (!this.selectedBrand) {
        this.makeToastFaile('Vui lòng chọn thương hiệu!');
        return;
      }

      const productListedPrice = this.$v.form.listedPrice.$model
        ? unMaskPrice(this.$v.form.listedPrice.$model)
        : 0;
      if (productListedPrice === 0) {
        this.makeToastFaile('Vui lòng nhập giá niêm yết sản phẩm!');
        return;
      }

      if (this.selectedType === 3) {
        const sumComboPrice = this.sumComboPrice();

        if (toNumber(unMaskPrice(this.salePrice)) !== sumComboPrice) {
          this.makeToastFaile('Giá bán lẻ không bằng tổng giá sản phẩm combo!');
          return;
        }
      }
      const productNews = this.listProductNew.map((item) => {
        return {
          ...item,
          originalPrice: item.originalPrice
            ? unMaskPrice(item.originalPrice)
            : 0,
          sellingPrice: item.sellingPrice ? unMaskPrice(item.sellingPrice) : 0,
          returnSellingPrice: this.returnSellingPrice
            ? unMaskPrice(this.returnSellingPrice)
            : 0,
          wholesalePrice: this.wholesalePrice
            ? unMaskPrice(this.wholesalePrice)
            : 0,
          listedPrice: this.$v.form.listedPrice.$model
            ? unMaskPrice(this.$v.form.listedPrice.$model)
            : 0,
          productCategory: this.selectedCateId,
          productWebCategory: this.selectedExternalCateId,
          productType: this.selectedType,
          original: this.selectedOriginal,
          brand: this.selectedBrand,
          weight: this.weight,
          length: this.length,
          width: this.width,
          height: this.height,
          warrantyAddress: this.warrantyAddress,
          warrantyPhone: this.warrantyPhone,
          warrantyMonthNo: this.warrantyMonthNo,
          warrantyDescription: this.warrantyDescription,
          status: this.selectedStatus,
          note: this.note,
        };
      });

      const data = {
        id: this.productIdFromUrl,
        accessories: [],
        productName: productName,
        productCode: productCode,
        productCodeVat: this.productCodeVat ? this.productCodeVat.trim() : null,
        productNameVat: this.productNameVat ? this.productNameVat.trim() : null,
        productTradeName: this.productTradeName
          ? this.productTradeName.trim()
          : null,
        productBrandCode: this.productBrandCode
          ? this.productBrandCode.trim()
          : null,
        alias: this.alias && this.alias.trim(),
        barCode: this.barcode,
        parentProductId: this.selectedClassParent,
        productCategory: this.selectedCateId,
        productWebCategory: this.selectedExternalCateId,
        originalPrice: this.importPrice ? unMaskPrice(this.importPrice) : 0,
        sellingPrice: this.salePrice ? unMaskPrice(this.salePrice) : 0,
        returnSellingPrice: this.returnSellingPrice
          ? unMaskPrice(this.returnSellingPrice)
          : 0,
        unitId: this.$v.form.unitId.$model,
        wholesalePrice: this.wholesalePrice
          ? unMaskPrice(this.wholesalePrice)
          : 0,
        listedPrice: this.$v.form.listedPrice.$model
          ? unMaskPrice(this.$v.form.listedPrice.$model)
          : 0,
        productType: this.selectedType,
        original: this.selectedOriginal,
        brand: this.selectedBrand,
        color: color,
        size: size,
        weight: this.weight,
        length: this.length,
        width: this.width,
        height: this.height,
        warrantyAddress: this.warrantyAddress,
        warrantyPhone: this.warrantyPhone,
        warrantyMonthNo: this.warrantyMonthNo,
        warrantyDescription: this.warrantyDescription,
        status: this.selectedStatus,
        note: this.note,
        listProductChild: productNews,
        productVat: this.$v.form.productVat.$model,
      };
      ApiService.put('product/', data)
        .then(({ data }) => {
          this.makeToastSuccess(data.message);
          setTimeout(() => {
            this.$router.push({
              path: '/products',
            });
          }, TIME_TRIGGER);
        })
        .catch((error) => {
          if (error.response) {
            return this.makeToastFaile(
              error.response.data
                ? error.response.data.message
                : 'Có vấn đề xảy ra hãy thử lại',
            );
          }
          return this.makeToastFaile(
            error.message || error || 'Có vấn đề xảy ra hãy thử lại',
          );
        });
    },
    getProductById() {
      this.listProducts = [];
      ApiService.get(`product/${this.productIdFromUrl}`)
        .then(({ data }) => {
          this.form.unitId = data.data.unitId || null;
          this.isEditedProductCode = data.data.isEditedProductCode;
          this.selectedCateId = data.data.productCategory;
          this.selectedExternalCateId = data.data.productWebCategory;
          this.form.selectedCate = data.data.productCateName;
          this.form.selectedExternalCate = data.data.productExternalCateName;
          this.form.name = data.data.productName;
          this.form.code = data.data.productCode;
          this.note = data.data.note;
          this.productCodeVat = data.data.productCodeVat;
          this.productNameVat = data.data.productNameVat;
          this.productTradeName = data.data.productTradeName;
          this.productBrandCode = data.data.productBrandCode;
          this.alias = data.data.alias;
          this.returnSellingPrice =
            data.data.returnSellingPrice !== null
              ? data.data.returnSellingPrice
              : 0;
          this.unit = data.data.unit;
          this.selectedClassParent = data.data.parentProductId;
          this.selectedParent = data.data.parentProductName;
          this.barcode = data.data.barCode;
          this.selectedOriginal = data.data.original;
          this.selectedBrand = data.data.brand;
          this.selectedType = data.data.productType;
          this.warrantyAddress = data.data.warrantyAddress;
          this.warrantyPhone = data.data.warrantyPhone;
          this.warrantyMonthNo = data.data.warrantyMonthNo;
          this.warrantyDescription = data.data.warrantyDescription;
          this.importPrice = data.data.originalPrice;
          this.salePrice = data.data.sellingPrice;
          this.wholesalePrice = data.data.wholesalePrice;
          this.form.listedPrice = data.data.listedPrice;
          this.width = data.data.width;
          this.length = data.data.length;
          this.weight = data.data.weight;
          this.height = data.data.height;
          this.selectedColor = data.data.listColor;
          this.selectedColorOriginal = data.data.listColor.map((a) =>
            Object.assign({}, a),
          );
          this.selectedSize = data.data.listSize;
          this.selectedSizeOriginal = data.data.listSize.map((a) =>
            Object.assign({}, a),
          );
          this.selectedStatus = data.data.status;
          if (this.selectedType === this.productType.PRODUCT_COMBO) {
            this.showCombo = true;
            const productComboData = data.data.listProductInCombo;

            this.listProductInCombo = productComboData.map((item) => {
              return { ...item, isEdit: false };
            });
          } else {
            this.getListProductChild();
          }
          const brand = this.listBrand.find(
            (brand) => brand.id === this.selectedBrand,
          );
          this.form.searchBrandName = brand ? brand.name : '';
          this.form.productVat = data.data.productVat;
        })
        .catch(() => {
          this.makeToastFaile('Lỗi');
        });

    },
    getListProductInCombo() {
      this.listProductInCombo = [];
      const param = {
        id: this.productIdFromUrl,
      };

      const paramQuery = {
        params: param,
      };
      ApiService.query('combos', paramQuery).then((response) => {
        const productComboData = response.data.data;
        this.listProductInCombo = productComboData.map((item) => {
          return { ...item, isEdit: false };
        });
      });
    },
    getListProductChild() {
      this.listProducts = [];
      ApiService.get(`product/get-list-child/${this.productIdFromUrl}`).then(
        (response) => {
          let count = 1;
          response.data.data.forEach((item) => {
            let product = {
              count: count,
              ...item,
            };
            count++;
            this.listProducts.push(product);
          });
        },
      );
    },
    onSelected(option) {
      this.selectedClassParent = option.item.id;
      let name = option.item.name;
      if (option.item.code !== '') {
        name = option.item.code + ' - ' + option.item.name;
      }
      this.selectedParent = name;
      this.genChildProduct();
    },
    onSelectedCate(option) {
      this.selectedCateId = option.item.id;
      this.form.selectedCate = option.item.name;
    },
    onInputChange(text) {
      this.selectedParent = text;

      this.debounceInputSearchProduct();
      this.genChildProduct();
    },
    onInputChangeProductCombo(text) {
      this.searchProductForCombo = text;

      this.debounceInputSearchProductCombo();
    },
    onInputCateChange(text) {
      this.form.selectedCate = text;

      const filteredData = this.cateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCateOptions = [...filteredData];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    getListExternalCate: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('productExternalCategory', paramQuery).then(
        ({ data }) => {
          this.numberOfPage = data.data.total_page;
          data.data.list_caterogy.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '-' + element.name;
            } else if (element.level === 2) {
              name = '--' + element.name;
            } else if (element.level === 3) {
              name = '---' + element.name;
            } else if (element.level === 4) {
              name = '----' + element.name;
            } else if (element.level === 5) {
              name = '-----' + element.name;
            } else if (element.level === 6) {
              name = '------' + element.name;
            } else {
              name = element.name;
            }
            let item = {
              id: element.id,
              name: element.name,
              suggestionName: name,
            };
            this.externalCateOptions.push(item);
          });
          this.filteredExternalCateOptions = [...this.externalCateOptions];
        },
      );
    },
    onSelectedExternalCate(option) {
      this.selectedExternalCateId = option.item.id;
      this.form.selectedExternalCate = option.item.name;
    },
    onInputExternalCateChange(text) {
      this.form.selectedExternalCate = text;

      const filteredData = this.externalCateOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredExternalCateOptions = [...filteredData];
    },
    onSelectedProductForCombo(option) {
      this.selectedProductForCombo.id = option.item.id;
      this.selectedProductForCombo.name = option.item.name;
      this.selectedProductForCombo.code = option.item.code;
      this.searchProductForCombo = option.item.name;
    },
    debounceInputSearchProduct: debounce(function () {
      this.searchProductAPI(1);
    }, TIME_TRIGGER),
    debounceInputSearchProductCombo: debounce(function () {
      this.searchProductAPI(2);
    }, TIME_TRIGGER),
    searchProductAPI(type) {
      if (!this.isSearching) {
        let textSearch = '';
        if (type === 1) {
          textSearch = this.selectedParent ? this.selectedParent.trim() : '';
        } else if (type === 2) {
          textSearch = this.searchProductForCombo
            ? this.searchProductForCombo.trim()
            : '';
        }
        this.searchProduct(textSearch);
      }
    },
    getHistoryByProductId() {
      this.histories = [];
      ApiService.get(`product/histories/${this.productIdFromUrl}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.histories = data.data;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(() => {
          this.makeToastFaile('Lỗi');
        });
    },
    viewHistoryDetail(id) {
      const item = this.histories.find((x) => x.id === id);
      this.history = item ? item : this.history;
      this.$refs['history-modal'].show();
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          const filteredProducts = this.listProductNew.filter((product) => {
            return product.id == item.id;
          });
          this.listProductNew = [].concat(filteredProducts)
        }
      });
    },
    onSelectedBrand(option) {
      this.selectedBrand = option.item.id;
      this.form.searchBrandName = option.item.name;
    },
    onInputBrandChange(text) {
      this.form.searchBrandName = text;

      const filteredData = this.brandOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredBrandOptions = [...filteredData];
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
<style lang="scss">
.edit-product {
  .border-grey {
    border-color: #e5eaee !important;
  }
  .input-group-append {
    cursor: pointer;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }
  .multi-select-wrapper,
  .auto-autosuggest-wrap-component {
    flex: 1 1 auto;
  }
  .inputText {
    width: 22%;
  }
  .icon:hover {
    background-color: #90c6fc;
  }

  .card-header-tabs {
    margin-right: -21px !important;
    margin-left: -21px !important;
  }

  .full-width .nav-tabs .nav-item {
    margin-bottom: -1px;
    flex-grow: 1 !important;
    text-align: center !important;
  }
  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  .label-header-form {
    font-size: 16px;
  }

  .form-group label {
    font-weight: 500;
  }
  .lable-sp {
    font-weight: 500;
  }

  .orderCode:hover {
    text-decoration: underline;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>
